button, .button {
    flex: 1;
    min-width: 200px;
    font-family: "Nunito Bold", serif;
    font-size: 20px;
    color: white;
    display:inline-block;
    padding: 10px;
    border-radius: 0.4rem;
    border:0.1em solid white;
    margin:0 0.2em 0.2em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
    background-color: transparent;
    text-align:center;
    transition: all 0.5s;
}
button:hover, .button:hover {
    background-color: rgba(32, 58, 67, 1);
    text-decoration: none;
}

button:focus, .button:focus {
    outline: none;
}

@media screen and (max-width: 640px) {
    button, .button {
        width: 100%;
    }
}

