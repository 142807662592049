@import url(https://fonts.googleapis.com/css?family=Dosis);
.menu {
    margin: 10px;
    margin-bottom: 30px;
}

.achievement-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
}

.achievement {
    border-radius: 7px;
    border: 1px #004758 solid;
    padding: 10px;
    margin: 0 0 0;
    min-height: 75px;
    -webkit-transition: background-color 0.2s, -webkit-transform 250ms;
    transition: background-color 0.2s, -webkit-transform 250ms;
    transition: transform 250ms, background-color 0.2s;
    transition: transform 250ms, background-color 0.2s, -webkit-transform 250ms;
    background: rgba(0,0,0,0.1);
}

.editing {
    border: 2px dashed #B33C8E;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

.achievement .topBar {
    display: flex;
    flex-direction: row;
}

.achievement h3 {
    flex: 3 1;
    margin-bottom: 0;
}

.actions.left {
    flex: 1 1;
    align-content: start;
    text-align: left;
}

.actions.right {
    flex: 1 1;
    visibility: hidden;
    text-align: right;
}

.actions.right img {
    width: 30px;
}

.achievement:hover .actions.right {
    visibility: visible;
}

.achievement .titleBlock {
    display: flex;
    align-items: center;
    justify-content: center;
}

.achievement .description {
    margin-left: 25px;
    margin-right: 25px;
}

.edit:hover, .delete:hover, .archive:hover, .unarchive:hover {
    -webkit-filter: drop-shadow(0 0 0.125rem);
            filter: drop-shadow(0 0 0.125rem);
    -webkit-transition: -webkit-filter 250ms;
    transition: -webkit-filter 250ms;
    transition: filter 250ms;
    transition: filter 250ms, -webkit-filter 250ms;
    cursor: pointer;
}

.achievement .footer {
    text-align: right;
}

.achievement.validated {
    border: 1px solid #3cb39d;
    background-color: #3cb39d;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
}

input[type=text].title:focus, input[type=text].description:focus {
    outline: none !important;
    -webkit-filter: drop-shadow(0 0 0.25rem #2c5364);
            filter: drop-shadow(0 0 0.25rem #2c5364);
    -webkit-transition: -webkit-filter 250ms;
    transition: -webkit-filter 250ms;
    transition: filter 250ms;
    transition: filter 250ms, -webkit-filter 250ms;
}


input[type=text].title, input[type=text].description {
    border: none;
    color: white;
    background-color: #182d35;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 5px;
}

input[type=text].title::-webkit-input-placeholder, input[type=text].description::-webkit-input-placeholder {
    color: #6f7e84 !important;
    font-family: "Nunito Bold", serif;
}

input[type=text].title:-ms-input-placeholder, input[type=text].description:-ms-input-placeholder {
    color: #6f7e84 !important;
    font-family: "Nunito Bold", serif;
}

input[type=text].title::-ms-input-placeholder, input[type=text].description::-ms-input-placeholder {
    color: #6f7e84 !important;
    font-family: "Nunito Bold", serif;
}

input[type=text].title::placeholder, input[type=text].description::placeholder {
    color: #6f7e84 !important;
    font-family: "Nunito Bold", serif;
}

input[type=text].title {
    margin-bottom: 0;
    width: 100%;
    font-family: 'Nunito Bold', serif;
    font-size: 28px;
    background-color: transparent;
    border-radius: 10px 10px 2px 2px;
}

input[type=text].description {
    width: 100%;
    background-color: transparent;
    font-family: 'Nunito Bold', serif;
}

.newBlock:not(.active) img.add {
    position: absolute;
    top: 10px;
    left: 22px;
    width: 53px;
}

.newBlock.active img.add {
    display: none;
}

.newBlock.active img.cancel {
    position: absolute;
    top: 10px;
    left: 22px;
    width: 40px;
}

.bigblock {
    display: flex;
    align-items: center;
    margin: 10px 10px 0 10px;
}

.confirmBlock {
    flex: 1 1;
    cursor: pointer;
    float: right;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 10px;
    -webkit-transition: background-color 0.2s, -webkit-transform 250ms;
    transition: background-color 0.2s, -webkit-transform 250ms;
    transition: transform 250ms, background-color 0.2s;
    transition: transform 250ms, background-color 0.2s, -webkit-transform 250ms;
}

.recurrenceBlock {
    flex: 0 1;
    float: left;
}

.confirmBlock:hover {
    background-color: #1a3d45;
    -webkit-transition: background-color 0.2s, -webkit-transform 250ms;
    transition: background-color 0.2s, -webkit-transform 250ms;
    transition: transform 250ms, background-color 0.2s;
    transition: transform 250ms, background-color 0.2s, -webkit-transform 250ms;
}

.newBlock.active img.confirm {
    position: relative;
    width: 35px;
}

.newBlock.active .confirmText {
    margin-left: 10px;
    font-family: "Nunito Black";
    font-size: 17px;
    color: #3cb39d;
}

img.enter {
    position: absolute;
    width: 50px;
    right: 50px;
    top: 10px;
}

.newBlock:not(.active) img.cancel, .newBlock:not(.active) .confirmBlock {
    display: none;
}

.newBlock {
    /*margin-bottom: 30px;*/
}

.newBlock:not(.active) {
    padding-top: 13px !important;
}

.newBlock.active {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    border-radius: 10px;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
}

.showBlock2 {
    display:block;
    overflow:hidden;
    -webkit-transition: max-height 0.5s, opacity 1s;
    transition: max-height 0.5s, opacity 1s;
    max-height: 100px;
    opacity: 1;
    visibility: visible;
}

.hideBlock2 {
    max-height:0;
    visibility: hidden;
    -webkit-transition: max-height 0.5s, opacity 0.5s, visibility 0.5s;
    transition: max-height 0.5s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
}

.dragging {
    background-color: #182d35;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
    border: 4px dashed #3cb39d;
}

.mainList {
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
}

.achievement .tag {
    font-size: 14px;
    display: inline-block;
    padding: 5px 10px 5px 10px;
    background-color: #3cb39d;
    border-radius: 5px;
    color: white;
    margin-left: 10px;
}

.achievement .tag.archived {
    font-size: 14px;
    display: inline-block;
    padding: 5px 10px 5px 10px;
    background-color: #B33C8E;
    border-radius: 5px;
    color: white;
    margin-left: 10px;
}

.achievement.validated .tag {
    border: 1px solid white;
}

.filter {
    margin-left: 10px;
    font-size: 14px;
    display: inline-block;
    padding: 5px 10px 5px 10px;
    background-color: #3cb39d;
    -webkit-transition: background-color 0.2s, -webkit-transform 250ms;
    transition: background-color 0.2s, -webkit-transform 250ms;
    transition: transform 250ms, background-color 0.2s;
    transition: transform 250ms, background-color 0.2s, -webkit-transform 250ms;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    border: 1px solid transparent;;
}


.filter:hover {
    opacity: 0.9;
}

.filter.disabled {
    font-size: 14px;
    display: inline-block;
    padding: 5px 10px 5px 10px;
    background-color: transparent;
    -webkit-transition: background-color 0.2s, -webkit-transform 250ms;
    transition: background-color 0.2s, -webkit-transform 250ms;
    transition: transform 250ms, background-color 0.2s;
    transition: transform 250ms, background-color 0.2s, -webkit-transform 250ms;
    border-radius: 5px;
    color: #3cb39d;
    border: 1px solid #3cb39d;
}

.customModal {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    margin-left: 10px;
    padding: 20px;
    max-width: 500px;
    border: 1px solid rgb(204, 204, 204);
    outline: none;
    background: #182d35;
    overflow: auto;
    border-radius: 4px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
}

.accomplishment {
    border-radius: 7px;
    border: 1px #004758 solid;
    padding: 10px;
    margin: 0 0 10px;
    background: rgba(0,0,0,0.1);
}

.accomplishment p {
    margin-bottom: 0;
}

.accomplishment .done {
    color: #3cb39d;
}

.accomplishment .canceled {
    color: orange;
}

.accomplishment .main {
}

.accomplishment .sub {
    font-size: 14px;
}

@media screen and (max-width: 640px) {
    img.add, img.cancel {
        display: none;
    }

    input[type=text].title {
        font-size: 5vw;
    }

    .achievement {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .topbar.withFooter {
        margin-bottom: 10px;
    }

    .showBlock2 {
        max-height: none;
    }

    .hideOnSmallScreen {
        display: none;
    }

    .noMinSize {
        min-width: 0 !important;
        min-width: initial !important;
    }
}

@media screen and (min-width: 641px) {
    .hideOnLargeScreen {
        display: none;
    }

    .bigblock {
        justify-content: flex-end;
    }

    .confirmBlock {
        flex: 0 1;
        min-width: 150px;
    }
}

input[type=checkbox] + label {
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #fff;
    border-radius: 0.2em;
    display: inline-block;
    width: 2em;
    height: 2em;
    padding-left: 0;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    -webkit-transition: .2s;
    transition: .2s;
    text-align: center;
}

input[type=checkbox].small + label:before {
    font-size: 14px;
}

input[type=checkbox].big + label:before {
    font-size: 20px;
}

input[type=checkbox] + label:active:before {
    -webkit-transform: scale(0);
            transform: scale(0);
}

input[type=checkbox]:checked + label:before {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}

input[type=checkbox]:disabled + label:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    border-color: #aaa;
}

input[type=checkbox]:checked:disabled + label:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}

button, .button {
    flex: 1 1;
    min-width: 200px;
    font-family: "Nunito Bold", serif;
    font-size: 20px;
    color: white;
    display:inline-block;
    padding: 10px;
    border-radius: 0.4rem;
    border:0.1em solid white;
    margin:0 0.2em 0.2em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
    background-color: transparent;
    text-align:center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
button:hover, .button:hover {
    background-color: rgba(32, 58, 67, 1);
    text-decoration: none;
}

button:focus, .button:focus {
    outline: none;
}

@media screen and (max-width: 640px) {
    button, .button {
        width: 100%;
    }
}


a:hover.button {
    color: inherit;
}

.socialLogin {
  display: inline-flex;
  flex-direction: column;
}

.socialLogin img {
  width: 250px;
  cursor: pointer;
}

.socialLogin .network {
  color: white;
  margin: 10px;
  vertical-align: middle;
}

.formBlock {
  max-width: 500px;
  margin: 0 auto;
}

.buttonsBlock {
  display: flex;
}

img.alert {
  width: 75px;
  display: inline;
  margin: 0;
}

.alertBlock {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  padding-bottom: 15px;
  border: 1px #3cb39d solid;
  border-radius: 10px;
}

.alertBlock p {
  margin: 0;
}

@media screen and (max-width: 640px) {
  .buttonsBlock {
    display: block;
  }
}

.input {
    position: relative;
    margin-top: 14px;
    padding-top: 20px;
}

.input-text {
    text-align: center;
    color: #0f2027;
    display: block;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-weight: inherit;
    line-height: 45px;
    border: none;
    border-radius: 0.4rem;
    -webkit-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
}

.input-text:focus {
    outline: none;
    box-shadow: 1px 1px white;
}

.input-label {
    display: block;
    position: absolute;
    bottom: 75%;
    left: 20px;
    color: #fff;
    font-family: inherit;
    font-weight: inherit;
    line-height: 50px;
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0) scale(1);
            transform: translate3d(0, 50%, 0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transition:
            opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            visibility 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            z-index 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:
            opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            visibility 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            z-index 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:
            opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            visibility 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            z-index 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:
            opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            visibility 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            z-index 0ms 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.input-text:placeholder-shown + .input-label {
    visibility: hidden;
    z-index: -1;
}

.input-text::-webkit-input-placeholder {
    color: #2f697a !important;
    font-family: "Nunito Bold", serif;
}

.input-text:-ms-input-placeholder {
    color: #2f697a !important;
    font-family: "Nunito Bold", serif;
}

.input-text::-ms-input-placeholder {
    color: #2f697a !important;
    font-family: "Nunito Bold", serif;
}

.input-text::placeholder {
    color: #2f697a !important;
    font-family: "Nunito Bold", serif;
}

.input-text:not(:placeholder-shown) + .input-label,
.input-text:focus:not(:placeholder-shown) + .input-label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    -webkit-transform: translate3d(0, 50%, 0) scale(0.8);
            transform: translate3d(0, 50%, 0) scale(0.8);
    -webkit-transition: visibility 0ms, z-index 0ms, -webkit-transform 0.5s;
    transition: visibility 0ms, z-index 0ms, -webkit-transform 0.5s;
    transition: transform 0.5s, visibility 0ms, z-index 0ms;
    transition: transform 0.5s, visibility 0ms, z-index 0ms, -webkit-transform 0.5s;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), local('Nunito-Regular'),
  url(/static/media/nunito-v12-latin-regular.508e414e.woff2) format('woff2'),
  url(/static/media/nunito-v12-latin-regular.e5875b85.woff) format('woff');
}

@font-face {
  font-family: 'Nunito Bold';
  src: local('Nunito Bold'), local('Nunito-Bold'),
  url(/static/media/nunito-v12-latin-700.a22acb48.woff2) format('woff2'),
  url(/static/media/nunito-v12-latin-700.7d90308f.woff) format('woff');
}

@font-face {
  font-family: 'Nunito Black';
  src: local('Nunito Black'), local('Nunito-Black'),
  url(/static/media/nunito-v12-latin-900.f82c6fb4.woff2) format('woff2'),
  url(/static/media/nunito-v12-latin-900.19de3736.woff) format('woff');
}

body {
  color: white;
  font-size: 18px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: -webkit-gradient(linear, left top, right top, from(#0f2027), color-stop(#203740), to(#0f2027));
  background: linear-gradient(to right, #0f2027, #203740, #0f2027);
}

.nopadding {
  padding: 0 !important;
}

h1 {
  color: white;
  font-family: 'Nunito Bold', serif;
}

h2 {
  color: #4296ae;
  font-family: 'Nunito Bold', serif;
}

h3 {
  font-family: "Nunito Bold", serif;
}

footer {
  margin-top: 30px;
}

footer a {
  color: #3cb39d;
  cursor: pointer;
}

footer a:not([href]) {
  color: #3cb39d !important;
}

.logo {
  width: 60px;
}

.mentionsBlock {
  margin-top: 25px;
  text-align: left;
}

.row {
  margin: 0;
}

.warning {
  border: 2px orange solid;
  border-radius: 5px;
  padding: 10px;
}

.warning .primary {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
}

.warning .secondary {
  border-top: 2px orange solid;
  border-top: 2px orange solid;
  margin-top: 10px;
  padding-top: 10px;
}

.warning .primary .icon {
  flex: 0 1;
}

.warning .primary .text {
  flex: 1 1;
}

.warning img {
  height: 50px;
}

.warning p {
  margin: 0;
}

.cookie-full-screen {
  color: white;
  text-align: center;
  z-index: 1000;
  background-color: rgba(60, 179, 157, 0.8);
  background: repeating-linear-gradient(
          45deg,
          /*rgba(60, 179, 157, 0.8),*/
          /*rgba(60, 179, 157, 0.8) 50px,*/
          /*rgba(60, 169, 150, 0.8) 50px,*/
          /*rgba(60, 169, 150, 0.8) 100px*/
          rgba(61, 193, 171, 0.8),
          rgba(61, 193, 171, 0.8) 50px,
          rgba(61, 182, 160, 0.8) 50px,
          rgba(61, 182, 160, 0.8) 100px
  );
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.cookie-banner {
  z-index: 1000;
  opacity: 1;
  text-align: center;
  color: white;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0;
  background-color: #3cb39d;
  border-radius: 0 0 10px 10px;
}

.cookie-banner .label {
  margin-bottom: 10px;
}

.cookie-button {
  color: white;
  min-width: 200px;
  padding: 10px;
  border: 0.1em solid white;
  background-color: transparent;
  border-radius: 10px;
  margin: 5px;
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.cookie-button.accept {
  font-weight: bold;
}

.cookie-button:hover {
  background-color: #369a84;
  cursor: pointer;
}

.cookie-banner.active {
  display: block;
}

.cookie-inform-and-ask {
  color: white;
  text-align: center;
  z-index: 1000;
  background-color: #3cb39d;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.cookie-inform-and-ask.active {
  display: block;
}

.cookie-dialog {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70%;
}

@media screen and (max-width: 640px) {
  h3 {
    font-size: 21px;
  }
}

