input[type=checkbox] + label {
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #fff;
    border-radius: 0.2em;
    display: inline-block;
    width: 2em;
    height: 2em;
    padding-left: 0;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    text-align: center;
}

input[type=checkbox].small + label:before {
    font-size: 14px;
}

input[type=checkbox].big + label:before {
    font-size: 20px;
}

input[type=checkbox] + label:active:before {
    transform: scale(0);
}

input[type=checkbox]:checked + label:before {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}

input[type=checkbox]:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
}

input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}
