@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), local('Nunito-Regular'),
  url('assets/fonts/nunito-v12-latin-regular.woff2') format('woff2'),
  url('assets/fonts/nunito-v12-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito Bold';
  src: local('Nunito Bold'), local('Nunito-Bold'),
  url('assets/fonts/nunito-v12-latin-700.woff2') format('woff2'),
  url('assets/fonts/nunito-v12-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Nunito Black';
  src: local('Nunito Black'), local('Nunito-Black'),
  url('assets/fonts/nunito-v12-latin-900.woff2') format('woff2'),
  url('assets/fonts/nunito-v12-latin-900.woff') format('woff');
}

body {
  color: white;
  font-size: 18px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: linear-gradient(to right, #0f2027, #203740, #0f2027);
}

.nopadding {
  padding: 0 !important;
}

h1 {
  color: white;
  font-family: 'Nunito Bold', serif;
}

h2 {
  color: #4296ae;
  font-family: 'Nunito Bold', serif;
}

h3 {
  font-family: "Nunito Bold", serif;
}

footer {
  margin-top: 30px;
}

footer a {
  color: #3cb39d;
  cursor: pointer;
}

footer a:not([href]) {
  color: #3cb39d !important;
}

.logo {
  width: 60px;
}

.mentionsBlock {
  margin-top: 25px;
  text-align: left;
}

.row {
  margin: 0;
}

.warning {
  border: 2px orange solid;
  border-radius: 5px;
  padding: 10px;
}

.warning .primary {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.warning .secondary {
  border-top: 2px orange solid;
  border-top: 2px orange solid;
  margin-top: 10px;
  padding-top: 10px;
}

.warning .primary .icon {
  flex: 0;
}

.warning .primary .text {
  flex: 1;
}

.warning img {
  height: 50px;
}

.warning p {
  margin: 0;
}

.cookie-full-screen {
  color: white;
  text-align: center;
  z-index: 1000;
  background-color: rgba(60, 179, 157, 0.8);
  background: repeating-linear-gradient(
          45deg,
          /*rgba(60, 179, 157, 0.8),*/
          /*rgba(60, 179, 157, 0.8) 50px,*/
          /*rgba(60, 169, 150, 0.8) 50px,*/
          /*rgba(60, 169, 150, 0.8) 100px*/
          rgba(61, 193, 171, 0.8),
          rgba(61, 193, 171, 0.8) 50px,
          rgba(61, 182, 160, 0.8) 50px,
          rgba(61, 182, 160, 0.8) 100px
  );
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.cookie-banner {
  z-index: 1000;
  opacity: 1;
  text-align: center;
  color: white;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0;
  background-color: #3cb39d;
  border-radius: 0 0 10px 10px;
}

.cookie-banner .label {
  margin-bottom: 10px;
}

.cookie-button {
  color: white;
  min-width: 200px;
  padding: 10px;
  border: 0.1em solid white;
  background-color: transparent;
  border-radius: 10px;
  margin: 5px;
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.cookie-button.accept {
  font-weight: bold;
}

.cookie-button:hover {
  background-color: #369a84;
  cursor: pointer;
}

.cookie-banner.active {
  display: block;
}

.cookie-inform-and-ask {
  color: white;
  text-align: center;
  z-index: 1000;
  background-color: #3cb39d;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.cookie-inform-and-ask.active {
  display: block;
}

.cookie-dialog {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

@media screen and (max-width: 640px) {
  h3 {
    font-size: 21px;
  }
}
